<template>
  <div class="app-container">
    <div>
      <div>
        <el-select
          v-if="!this.$store.getters.restaurantId"
          v-model="restaurantID"
          class="search-select"
          type="select"
          :clearable="true"
          style="margin-bottom: 10px"
          :placeholder="$t('common.restaurant')"
          @input="handleFilterChanged($event)"
        >
          <el-option
            v-for="restaurant in restaurants"
            :key="restaurant.id"
            :label="restaurant.name"
            :value="restaurant.id"
          />
        </el-select>
        <el-date-picker
          v-model="fromDate"
          type="date"
          style="margin-left: 4px"
          :format="'dd-MM-yyyy'"
          :placeholder="$t('report.fromDate')"
          name="date"
          :picker-options="pickerOptions"
          @input="handleFilterChanged($event)"
        />
        <el-date-picker
          v-model="untilDate"
          type="date"
          style="margin-left: 4px"
          :format="'dd-MM-yyyy'"
          :placeholder="$t('report.untilDate')"
          name="date"
          :picker-options="pickerOptions"
          @input="handleFilterChanged($event)"
        />
        <el-checkbox
          v-model="groupByDateYN"
          :label="$t('deliveryReport.groupByDate')"
          style="margin-left: 4px"
          @input="handleFilterChanged($event)"
        />
        <el-checkbox
          v-model="groupByDelivererYN"
          :label="$t('deliveryReport.groupByDeliverer')"
          style="margin-left: 4px"
          @input="handleFilterChanged($event)"
        />
      </div>
    </div>
    <div v-if="dataIsLoaded" ref="reportContainer" class="reportContainer page">
      <el-row :gutter="20">
        <el-col :xs="24" :lg="24">
          <div class="grid-content report-block">
            <el-row style="padding-top:10px">
              <el-col :span="4" style="font-weight: bold">{{ $t('deliveryReport.period') }}</el-col>
              <el-col :span="3" style="font-weight: bold">{{ $t('deliveryReport.deliveryDude') }}</el-col>
              <el-col :span="3" style="font-weight: bold">{{ $t('deliveryReport.numberOfTickets') }}</el-col>
              <el-col :span="3" style="font-weight: bold">{{ $t('deliveryReport.timeInKitchen') }}</el-col>
              <el-col :span="3" style="font-weight: bold">{{ $t('deliveryReport.deliveryDuration') }}</el-col>
              <el-col :span="3" style="font-weight: bold">{{ $t('deliveryReport.customerWaitingTime') }}</el-col>
            </el-row>
            <el-row>
              <el-col :span="1" :offset="10" style="font-weight: bold">{{ $t('deliveryReport.average') }}</el-col>
              <el-col :span="1" style="font-weight: bold">{{ $t('deliveryReport.min') }}</el-col>
              <el-col :span="1" style="font-weight: bold">{{ $t('deliveryReport.max') }}</el-col>
              <el-col :span="1" style="font-weight: bold">{{ $t('deliveryReport.average') }}</el-col>
              <el-col :span="1" style="font-weight: bold">{{ $t('deliveryReport.min') }}</el-col>
              <el-col :span="1" style="font-weight: bold">{{ $t('deliveryReport.max') }}</el-col>
              <el-col :span="1" style="font-weight: bold">{{ $t('deliveryReport.average') }}</el-col>
              <el-col :span="1" style="font-weight: bold">{{ $t('deliveryReport.min') }}</el-col>
              <el-col :span="1" style="font-weight: bold">{{ $t('deliveryReport.max') }}</el-col>
            </el-row>
            <el-row
              v-for="deliveryReportLine in deliveryReportLines"
              :key="deliveryReportLine.id"
              :span="24"
              style="margin-top: 4px"
            >
              <div>
                <el-col :span="4" style="align-content: unset"> {{ deliveryReportLine.period }} </el-col>
                <el-col :span="3"> {{ deliveryReportLine.delivererName }} </el-col>
                <el-col :span="3"> {{ deliveryReportLine.numberOfTickets }} </el-col>
              </div>
              <div>
                <el-col :span="1"> {{ roundValue(deliveryReportLine.timeKitchen.timeAverage) }} </el-col>
                <el-col :span="1"> {{ roundValue(deliveryReportLine.timeKitchen.timeMin) }} </el-col>
                <el-col :span="1"> {{ roundValue(deliveryReportLine.timeKitchen.timeMax) }} </el-col>
              </div>
              <div>
                <el-col :span="1"> {{ roundValue(deliveryReportLine.timeDelivery.timeAverage) }} </el-col>
                <el-col :span="1"> {{ roundValue(deliveryReportLine.timeDelivery.timeMin) }} </el-col>
                <el-col :span="1"> {{ roundValue(deliveryReportLine.timeDelivery.timeMax) }} </el-col>
              </div>
              <div>
                <el-col :span="1"> {{ roundValue(deliveryReportLine.timeCustomerWaiting.timeAverage) }} </el-col>
                <el-col :span="1"> {{ roundValue(deliveryReportLine.timeCustomerWaiting.timeMin) }} </el-col>
                <el-col :span="1"> {{ roundValue(deliveryReportLine.timeCustomerWaiting.timeMax) }} </el-col>
              </div>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { toString } from '@/utils/date';
import { Loading } from 'element-ui';
export default {
  name: 'EmployeeWorkLog',
  data() {
    return {
      loading: false,
      dataIsLoaded: false,
      groupByDateYN: false,
      groupByDelivererYN: false,
      deliveryReportLines: {
        timeKitchen: {},
        timeDelivery: {},
        timeCustomerWaiting: {}
      },
      restaurantID: null,
      fromDate: new Date(),
      untilDate: new Date()
    };
  },
  computed: {
    listParams() {
      return {
        restaurantID: this.restaurantID,
        fromDate: toString(this.fromDate, 'YYYY-MM-DD'),
        untilDate: toString(this.untilDate, 'YYYY-MM-DD'),
        groupByDateYN: this.groupByDateYN,
        groupByDelivererYN: this.groupByDelivererYN
      };
    },
    restaurants() {
      return this.$store.getters['restaurant/loadedItems'].filter(e => e.onlineYN);
    }
  },
  created() {
    if (!this.$store.getters.restaurantId) {
      this.$store.dispatch('restaurant/getItems');
    }
    this.refreshList();
  },
  methods: {
    refreshList() {
      if (!this.restaurantID && !this.$store.getters.restaurantId) {
        return;
      }
      this.loadAsync();
    },
    async loadAsync() {
      return new Promise(() => {
        const loadingInstance = Loading.service({
          lock: true,
          background: 'rgba(255, 255, 255, 0.5)'
        });
        this.loading = true;
        this.$store
          .dispatch('restaurant/getDeliveryReport', this.listParams)
          .then(data => {
            this.deliveryReportLines = data.deliveryReportLines;
            this.dataIsLoaded = true;
          })
          .finally(() => {
            this.loading = false;
            loadingInstance.close();
          });
      });
    },
    pickerOptions() {
      return {
        disabledDate(givenDate) {
          const lastDate = new Date();
          const date = new Date(givenDate);
          return date > lastDate;
        },
        firstDayOfWeek: 1
      };
    },
    handleFilterChanged() {
      this.refreshList();
    },
    roundValue(value) {
      return Math.round(value);
    }
  }
};
</script>

<style>
.grid-content {
  margin-top: 20px;
}

.page {
  font-size: smaller;
  text-align: center;
}
</style>
